.bmtb_container {
  width: 100%;
  .bmtb_banner_parent {
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 4rem;
    height: 95vh;
    padding-top: 5%;
    box-shadow: 5px 5px 10px lightgrey;

    img {
      height: 100%;
      max-width: 100vw;
      margin: 0 auto;
    }
  }
  .bmtb_parent {
    width: 80%;
    margin: 0 auto;
    .bmtb_title_container {
      margin-bottom: 2rem;
      h1 {
        font-size: 100px;
        margin-bottom: 0;
      }
      p {
        // margin: 0;
        font-size: 35px;
        font-weight: 400;
      }
    }
    .bmtb_info {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      .bmtb_clientBrief {
        width: 50%;
        margin-right: 2%;
        margin-bottom: 4rem;
        span {
          margin-bottom: 0.5rem;

          h3 {
            margin: 0;
            font-size: 30px;
            font-weight: 800;
          }
          p {
            margin-top: 0;
            font-size: 20px;
            font-weight: 300;
          }
          div {
            margin-bottom: 1.2rem;
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      .mtb_insp_logo_container {
        width: 50%;

        .bmtb_insp {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          img {
            width: auto;
            max-height: 12rem;
            box-shadow: 5px 5px 10px lightgrey;
          }
        }
      }
    }
    .bmtb_sketches {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 4rem;

      img {
        width: 50%;
        box-shadow: 5px 5px 10px lightgrey;
      }
      div {
        margin-left: 5%;
        width: 45%;
        h3 {
          margin: 0;
          font-size: 25px;
          font-weight: 800;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .bmtb_ill_ideas {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 2rem;
      img {
        width: 50%;
        box-shadow: 5px 5px 10px lightgrey;
      }
      div {
        margin-right: 5%;
        width: 45%;
        h3 {
          margin: 0;
          font-size: 25px;
          font-weight: 800;
        }
        p {
          margin-top: 10p;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .bmtb_logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      div {
        width: 48%;
        margin-bottom: 1rem;
        p {
          // margin-bottom: 0;
        }
        img {
          box-shadow: 5px 5px 10px lightgrey;
          width: 100%;
        }
      }
    }
    .arrow {
      font-size: 25px;
      font-weight: 300;
      text-decoration: none;
      color: black;
      float: right;
      margin: 2rem 0;
    }

    .arrow:hover {
      color: grey;
    }
  }
  @media only screen and (max-width: 800px) {
    .bmtb_banner_parent {
      height: 100vh;
      padding-top: 0;
      img {
        width: 100vw;
        height: auto;
      }
    }
    .bmtb_parent {
      .bmtb_title_container {
        h1 {
          font-size: 70px;
        }
      }
      .bmtb_info {
        flex-direction: column;
        .bmtb_clientBrief {
          width: 100%;
          margin-right: 0;
          margin-bottom: 0;
          span {
            p {
              font-size: 25px;
            }
          }
        }
        .mtb_insp_logo_container {
          width: 100%;
          p {
            font-size: 25px;
          }
          .bmtb_insp {
            img {
              width: 100%;
              height: auto;
              max-height: 20rem;
              margin-bottom: 1.5rem;
            }
          }
        }
      }
      .bmtb_sketches {
        flex-direction: column-reverse;
        div {
          width: 100%;
          margin-left: 0;
          p {
            font-size: 25px;
          }
          h3 {
            font-size: 30px;
          }
        }
        img {
          width: 100%;
        }
      }
      .bmtb_ill_ideas {
        flex-direction: column-reverse;
        img {
          width: 100%;
        }
        div {
          margin-right: 0;
          width: 100%;
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
          }
        }
      }
      .bmtb_logos {
        flex-direction: column;
        div {
          width: 100%;
          p {
            font-size: 25px;
          }
        }
      }
    }
  }
}
