.gd_container {
  width: 80%;
  margin: 0 auto;
  .gd_title_parent {
    h1 {
      font-size: 100px;
      margin-top: 0;
    }
  }
  .gd_main_container {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .gd_container {
    width: 90%;
    .gd_main_container {
      margin-left: 0;
    }
  }
}
