.mall_container {
  .mall_banner_parent {
    width: 100%;
    background-color: #176767;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    min-height: 100vh;
    img {
      width: 70%;
    }
  }
  .mall_info_parent {
    width: 80%;
    margin: 0 auto;
    .mall_title_container {
      margin-bottom: 2rem;
      h1 {
        font-size: 100px;
        margin-bottom: 0;
      }
      p {
        margin: 0;
        font-size: 35px;
        font-weight: 400;
      }
    }
    .mall_info {
      display: flex;
      flex-direction: row;
      .mall_brief {
        width: 48%;
        margin-right: 2%;
        span {
          margin-bottom: 0.5rem;

          h3 {
            margin: 0;
            font-size: 30px;
            font-weight: 800;
          }
          p {
            margin-top: 0;
            font-size: 20px;
            font-weight: 300;
          }
          div {
            margin-bottom: 1.2rem;
            p {
              // margin-bottom: 0;
            }
          }
        }
      }
      .mall_sketch {
        display: flex;
        flex-direction: column;
        width: 45%;
        img {
          width: 100%;
        }
        h3 {
          margin: 0;
          font-size: 25px;
          font-weight: 800;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .mall_des_imgs {
      margin-top: 2rem;
      width: 100%;

      h3 {
        margin: 0;
        font-size: 35px;
        font-weight: 800;
      }
      .row-rev {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .mall_des_item {
          p,
          h3 {
            padding-left: 1rem;
          }
          img {
            margin-right: 2rem;
          }
        }
        div {
          h2 {
            font-size: 30px;
          }
          .mall_des_text {
            font-size: 20px;
          }
        }
      }
      .mall_des_process {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .mall_des_item {
          margin: 1%;
          width: 48%;
          margin-left: 0;

          img {
            width: 100%;
            box-shadow: 5px 5px 10px lightgrey;
          }
          h3 {
            margin: 0;
            font-size: 25px;
            font-weight: 800;
          }
          p {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
    }
    .arrow_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .arrow {
        font-size: 25px;
        font-weight: 300;
        text-decoration: none;
        color: black;
        // float: right;
        margin: 2rem 0;
      }

      .arrow:hover {
        color: grey;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .mall_banner_parent {
      img {
        width: 100%;
      }
    }
    .mall_info_parent {
      .mall_title_container {
        h1 {
          font-size: 70px;
        }
      }
      .mall_info {
        flex-direction: column;
        .mall_brief {
          margin-right: 0;
          width: 100%;
          span {
            p {
              font-size: 25px;
            }
          }
        }
        .mall_sketch {
          width: 100%;
        }
      }
      .mall_des_imgs {
        .mall_des_process {
          flex-direction: column;
          .row-rev {
            flex-direction: column-reverse;
            margin-top: 1rem;
            .mall_des_text {
              font-size: 25px;
            }
          }
          .mall_des_item {
            margin: 0.5rem 0;
            width: 100%;
            h3 {
              padding-left: 0;
              font-size: 30px;
            }
            p {
              font-size: 25px;
              margin-top: 1rem;
              padding-left: 0;
            }
            img {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
