.Cv_Container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5rem;
  .cv_title {
    h1 {
      font-size: 100px;
      margin-top: 0;
    }
  }
  .cv_text {
    .self_img {
      float: left;
      margin: 2rem 2rem 0rem 0rem;
      width: 30%;
      box-shadow: 5px 5px 10px lightgray;
    }
    .cv_item {
      text-align: justify;
      margin-bottom: 2rem;

      .cv_item_top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .cv_item_top_title {
          display: flex;
          flex-direction: column;
          //   margin-right: 1rem;
          min-width: 53%;
          h3 {
            font-size: 40px;
            margin-bottom: 0;
            text-align: left;
          }
          p {
            font-size: 25px;
            margin: 10px 0;
          }
        }
        .cv_item_top_icons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          float: right;
          width: auto;
          min-width: 24rem;

          img {
            width: auto;
            height: 5rem;
            margin: 0;
          }
        }
        .logo {
          display: flex;
          align-items: center;
          img {
            width: 7rem;
            height: auto;
            margin: 1rem 0;
          }
        }
      }
      p {
        margin: 0;
        font-size: 20px;
        a {
          color: black;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .Cv_Container {
    .cv_title {
      h1 {
        font-size: 60px;
      }
    }
    .cv_text {
      .self_img {
        margin: 0;
        width: 100%;
      }
      .cv_item {
        text-align: left;
        .cv_item_top {
          flex-direction: column;
          width: 100%;
          .cv_item_top_title {
            h3 {
              font-size: 35px;
            }
          }
          .cv_item_top_icons {
            min-width: auto;
            margin: 1rem 0;
            img {
              height: 3.5rem;
            }
          }
        }
      }
    }
  }
}
