.banner_container {
  width: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#header_video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.full_opaque_background {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #484848;
  opacity: 0.7;
  overflow: hidden;
}

.side_background {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 32%;
  color: white;
  z-index: 3;
}

.text_items h1 {
  font-size: 125px;
  margin-bottom: 0;
}

.text_items {
  position: relative;
  width: 100vw;
  margin: 10rem 0 10rem 10%;
  color: white;
  z-index: 4;
}

.text_items {
  a {
    text-decoration: none;
    color: white;
    h3 {
      font-size: 65px;
      margin: 0;
      padding-bottom: 0;
    }
    h3:hover {
      cursor: pointer;
      color: lightgrey;
    }
  }
}

.text_items h5 {
  font-size: 20px;
  margin: 0 0px 10px 0;
  font-weight: 300;
}

.vid_desktop {
  display: block;
}

.vid_mobile {
  display: none;
}

@media (max-width: 550px) {
  .banner_container {
    height: 80vh;
  }

  .text_items {
    margin: 1rem;
    h1 {
      font-size: 60px;
      margin-top: 100px;
    }
    a {
      h3 {
        font-size: 45px;
        padding-top: 10px;
      }
    }
    h5 {
      width: 80%;
    }
  }

  .side_background {
    height: 100%;
  }

  .full_opaque_background {
    height: 100%;
  }
  .vid_desktop {
    display: none;
  }

  .vid_mobile {
    display: block;
  }
}
