.banner_container_fth {
  width: 100vw;
  //   height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  .header_video {
    // height: 100%;
    width: 100vw;
    display: block;
  }

  .ratio::before {
    display: none;
  }

  #header_video_desk {
    display: block;
  }

  #header_video_mob {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    #header_video_desk {
      display: block;
    }

    #header_video_mob {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    #header_video_desk {
      display: none;
    }

    #header_video_mob {
      display: block;
    }
  }
}
// #header_video_fth {
//   position: absolute;
//   width: 100%;
//   left: 50%;
//   top: 50%;
//   height: 100%;
//   object-fit: cover;
//   transform: translate(-50%, -50%);
//   display: block;
// }

.fth_site_container {
  width: 90%;
  margin: 0 auto;
  position: relative;
  height: auto;
  .fth_subheading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    p {
      margin: 0;
      font-size: 35px;
      font-weight: 400;
    }
    a {
      img {
        width: 3rem;
        margin-left: 2rem;
      }
    }
  }
  .screenshots {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;

    .fth_desktop_home_container {
      width: 40%;
      margin-right: 2rem;
      .fth_desktop_home {
        width: 100%;
        box-shadow: 5px 5px 10px lightgrey;
      }
      p {
        margin-bottom: 0;
      }
    }
    .fth_mobile_home_container {
      width: 12%;
      .fth_mobile_home {
        width: 100%;
        box-shadow: 5px 5px 10px lightgrey;
      }
      p {
        margin-bottom: 0;
      }
    }
    .fth_home_hover_container {
      width: 40%;
      margin-left: 2rem;
      div {
        margin-top: 0;
        .fth_hover_tag {
          font-size: 15px !important;
        }
      }
      .fth_hover {
        width: 100%;
        box-shadow: 5px 5px 10px lightgrey;
      }
      p {
        margin-bottom: 0;
        font-size: 20px;
      }
      h3 {
        font-size: 25px;
        margin-bottom: 0;
      }
      h4 {
        font-size: 20px;
        text-align: left;
        margin: 0;
      }
    }
  }
  h1 {
    font-size: 100px;
    margin-bottom: 0;
  }
  h2 {
    margin: 0;
    font-size: 35px;
    font-weight: 400;
    align-items: center;
    display: flex;
    a {
      img {
        width: 3rem;
        margin-left: 1rem;

        //   margin-top: 5px;
        //   margin-bottom: 2rem;
      }
    }
  }
  .arrow_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .arrow {
      font-size: 25px;
      font-weight: 300;
      text-decoration: none;
      color: black;
      // float: right;
      margin: 2rem 0;
    }
    .arrow:hover {
      color: grey;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    .fth_subheading {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      a {
        img {
          margin-left: 0;
        }
      }
    }
    h1 {
      font-size: 70px;
    }
    h2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a {
        margin-top: 1rem;
        img {
          width: 4rem;
        }
      }
    }
    .screenshots {
      flex-direction: column-reverse;
      .fth_desktop_home_container {
        width: 100%;
        p {
          font-size: 25px;
        }
      }
      .fth_mobile_home_container {
        width: 100%;
        p {
          font-size: 25px;
        }
      }
      .fth_home_hover_container {
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: column-reverse;
        .fth_hover {
          width: 100%;
        }
        h3 {
          margin-top: 0;
        }
        p {
          font-size: 25px;
        }
      }
    }
    a {
      img {
        margin: 1rem 0;
      }
    }
  }
}
