.md_container {
  .md_banner_parent {
    width: 100vw;
    background-color: #e5e282;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 4rem;
    img {
      height: 80%;
      max-width: 100vw;
      max-height: 80vh;
      margin: 0 auto;
    }
  }
  .md_info_container {
    width: 80%;
    margin: 0 auto;

    .md_title_parent {
      margin-bottom: 2rem;
      h1 {
        font-size: 100px;
        margin-bottom: 0;
      }
      p {
        margin: 0;
        font-size: 35px;
        font-weight: 400;
      }
    }
    .md_briefSketch {
      display: flex;
      flex-direction: row;
      .md_brief {
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 30rem;
        .md_brief_item {
          //   margin-bottom: 0.5rem;
          width: auto;
          margin: 0 2rem;
          width: 37%;
          h3 {
            margin: 0;
            font-size: 30px;
            font-weight: 800;
          }
          p {
            margin-top: 0;
            font-size: 20px;
            font-weight: 300;
          }
          div {
            margin-bottom: 1.2rem;
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      .md_sketch {
        width: 50%;
        img {
          width: 100%;
          box-shadow: 5px 5px 10px lightgrey;
        }
        h3 {
          margin: 0;
          font-size: 25px;
          font-weight: 800;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .md_process {
      margin-top: 4rem;
      display: flex;
      flex-direction: row;
      width: 100%;

      img {
        width: 50%;
        box-shadow: 5px 5px 10px lightgrey;
        margin-right: 2rem;
      }
      div {
        h3 {
          margin: 0;
          font-size: 25px;
          font-weight: 800;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .md_process_cont {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      div {
        max-width: 49%;
        // margin: 1rem auto auto 0;
        // margin-right: 2%;
        p {
          margin-bottom: 0;
        }
        img {
          width: 100%;
          box-shadow: 5px 5px 10px lightgrey;
          min-height: 10rem;
        }
      }
    }
    .md_forClient {
      width: 100%;
      margin-top: 3rem;
      h3 {
        margin: 0;
        font-size: 25px;
        font-weight: 800;
      }
      .md_forClient_imgs {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        div {
          max-width: 48%;
          margin: 0.5rem;
          img {
            width: 100%;
            box-shadow: 5px 5px 10px lightgrey;
          }
          p {
            // display: none;
          }
        }
      }
    }
    .arrow_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .arrow {
        font-size: 25px;
        font-weight: 300;
        text-decoration: none;
        color: black;
        // float: right;
        margin: 2rem 0;
      }
      .arrow:hover {
        color: grey;
      }
    }
    @media only screen and (max-width: 800px) {
      .md_title_parent {
        h1 {
          font-size: 70px;
          margin-top: 0.5rem;
        }
      }
      .md_briefSketch {
        flex-direction: column;
        .md_brief {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
          height: auto;
          flex-direction: column;
          .md_brief_item {
            margin: 0;
            width: 100%;
            div {
              p {
                font-size: 25px;
              }
            }
          }
        }
        .md_sketch {
          width: 100%;
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
          }
        }
      }
      .md_process {
        flex-direction: column-reverse;
        margin-top: 2rem;
        div {
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
          }
        }

        img {
          width: 100%;
          margin-right: 0;
        }
      }
      .md_process_cont {
        flex-direction: column;
        div {
          max-width: 100%;
          p {
            font-size: 25px;
          }
        }
      }
      .md_forClient {
        flex-direction: column;
        h3 {
          font-size: 30px;
        }
        .md_forClient_imgs {
          flex-direction: column;
          div {
            max-width: 100%;
            margin: 0.5rem 0;
          }
        }
      }
    }
  }
}
