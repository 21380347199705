.webdev_container {
  width: 80%;
  margin: 0 auto;
  .webdev_title {
    h1 {
      font-size: 100px;
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 800px) {
  .webdev_container {
    width: 90%;
    .webdev_title {
      h1 {
        font-size: 50px;
      }
    }
  }
}
