.de_container {
  width: 80%;
  margin: 0 auto;
  .de_title_parent {
    margin-bottom: 2rem;
    h1 {
      font-size: 100px;
      margin-bottom: 0;
    }
    p {
      margin: 0;
      font-size: 35px;
      font-weight: 400;
    }
  }
  .de_main_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .de_img {
      width: 49%;
      display: flex;
      flex-direction: column;
      div {
        img {
          width: 100%;
          box-shadow: 5px 5px 10px lightgray;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .de_desc {
      width: 49%;
      h3 {
        margin: 0;
        font-size: 25px;
        font-weight: 800;
      }
      p {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 300;
      }
      img {
        width: 100%;
        margin-bottom: 1rem;
        box-shadow: 5px 5px 10px lightgray;
      }
    }
  }
  .arrow_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .arrow {
      font-size: 25px;
      font-weight: 300;
      text-decoration: none;
      color: black;
      // float: right;
      margin: 2rem 0;
    }
    .arrow:hover {
      color: grey;
    }
  }
  @media only screen and (max-width: 800px) {
    .de_title_parent {
      h1 {
        font-size: 70px;
      }
    }
    .de_main_info {
      flex-direction: column;
      .de_desc {
        div {
          p {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 300;
          }
        }
        width: 100%;
        h3 {
          font-size: 30px;
          margin-top: 1rem;
        }
        p {
          font-size: 25px;
          margin-top: 1rem;
          padding-left: 0;
        }
      }
      .de_img {
        width: 100%;
      }
    }
  }
}
