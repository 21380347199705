.kb_title_container {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    top: 20px;
    position: absolute;
    text-align: center;
    z-index: 2;
    // background-color: rgba(0, 0, 0, 0.5);
    color: darkcyan;
    padding: 10px;
    font-size: 50px;
    border-radius: 10px;
  }

  h4 {
    z-index: 2;
    position: absolute;
    top: 160px;
    color: white;
    font-size: 20px;
  }

  h6 {
    z-index: 2;
    position: absolute;
    color: white;
    bottom: 0;
    left: 30px;
  }

  img {
    height: 100vh;
    width: auto;
    z-index: 1;
    overflow: hidden;
  }
}

.kb_main {
  width: auto;
  margin: 3rem 10rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    width: 50%;
    height: 42rem;
    max-height: auto;
    box-shadow: 5px 5px 10px lightgrey;
  }

  .kb_desc {
    display: flex;
    flex-direction: row;
    width: 47%;
    padding-left: 3%;

    h3 {
      font-size: 55px;
      margin: 0;
    }

    p {
      font-size: 20px;
      margin-top: 10px;
    }
  }
}

.kb_example {
  width: 47%;
  // margin-top: 3rem;
  text-align: center;
  background-color: lightblue;
  margin: auto;
  padding: 2rem;
  border-radius: 10px;

  .kb_example_title {
    font-size: 30px;
    margin-bottom: 5px;
  }
  .kb_example_title_sub {
    font-size: 40px;
    margin-top: 0;
  }
  .kb_example_text {
    font-size: 30px;
    color: black;
    font-style: italic;
    font-family: auto;
  }
}

h4 {
  font-size: 30px;
  width: 75%;
  text-align: center;
  margin: 3rem auto;
}

@media only screen and (max-width: 800px) {
  .kb_title_container {
    img {
      object-fit: cover;
    }
    h2 {
      font-size: 40px;
    }
  }

  .kb_main {
    margin: 0;
    flex-direction: column-reverse;

    img {
      width: 100%;
      height: auto;
      margin-bottom: 3rem;
    }

    .kb_desc {
      width: 90%;
      margin: 1rem auto;
      padding-left: 0;
      p {
        font-size: 25px;
      }
    }
  }
  .kb_example {
    margin-top: 0;
    width: 80%;
    padding: 1rem;

    .kb_example_title {
      width: 80%;
    }

    .kb_example_title_sub {
      width: 90%;
    }

    .kb_example_text {
      width: 80%;
      margin: auto;
      font-size: 25px;
    }
  }
  h4 {
    font-size: 22px;
  }
}

// .kb_title_container {
//   width: 100%;
//   //   padding: 4rem;
//   text-align: center;
//   font-size: 30px;
//   //   background-image: url("../../components/media/SabButterScotchAi.PNG");
//   //   background-repeat: no-repeat;
//   //   background-size: cover;
//   //   background-position: center;
//   height: 100vh;
//   max-height: 100%;
//   top: 0;
//   color: white;
//   z-index: 1;
//   position: relative;

//   .kb_title_background {
//     background-color: black;
//     width: 50%;
//     z-index: 2;
//     position: relative;
//     margin: 0 auto;
//     height: auto;
//     padding: 10px;
//     text-align: center;
//     border-radius: 10px;
//     opacity: 0.7;
//     margin-top: 30px;

//     h3 {
//       opacity: 1;
//       margin: 0 auto;
//     }
//   }
// }
