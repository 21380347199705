.selt_container {
  width: 80%;
  margin: 0 auto;
  .selt_title_parent {
    margin-bottom: 2rem;
    h1 {
      font-size: 100px;
      margin-bottom: 0;
    }
    p {
      margin: 0;
      font-size: 35px;
      font-weight: 400;
    }
  }
  .selt_main_info {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      //   margin: 0;
      font-size: 25px;
      font-weight: 800;
    }
    .selt_brief_img {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .selt_brief {
        display: flex;
        flex-direction: column;
        width: 48%;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          h4 {
            margin: 0;
            font-size: 25px;
            font-weight: 600;
            text-align: left;
          }
          p {
            margin-top: 0;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
      img {
        width: 50%;
        box-shadow: 5px 5px 10px lightgrey;
      }
    }
  }
  .arrow_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .arrow {
      font-size: 25px;
      font-weight: 300;
      text-decoration: none;
      color: black;
      // float: right;
      margin: 2rem 0;
    }
    .arrow:hover {
      color: grey;
    }
  }
  @media only screen and (max-width: 800px) {
    .selt_title_parent {
      h1 {
        font-size: 70px;
      }
    }
    .selt_main_info {
      h3 {
        margin-top: 0;
        font-size: 30px;
      }
      .selt_brief_img {
        flex-direction: column;

        .selt_brief {
          width: 100%;
          div {
            h4 {
              font-size: 30px;
            }
            p {
              font-size: 25px;
              //   margin-top: 1rem;
              padding-left: 0;
            }
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
