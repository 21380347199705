.side-drawer {
  height: 100%;
  background: red;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-102%);
  transition: transform 0.5s ease-in;
}

.side-drawer.open {
  transform: translateX(0);
}

.closed {
  transform: translateX(1);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding-left: 10%;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  color: darkgray;
  text-decoration: none;
  font-size: 20px;
  padding-bottom: 20px;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: lightgray;
}

@media (min-width: 550px) {
  .side-drawer {
    display: none;
  }
}
