.work_parent_cont {
  h2 {
    text-align: left;
    font-size: 40px;
    font-weight: 400;
  }
  .work_container {
    display: flex;
    flex-direction: column;
    .work_item {
      margin-bottom: 2rem;
      width: 100%;
      padding-left: 1rem;
      .work_item_logo {
        width: auto;
        left: 10%;
        margin-bottom: 0.5rem;
        max-height: 15rem;
      }
      .work_item_info {
        margin-bottom: 0.5rem;
        h4 {
          text-align: left;
          margin: 0;
          font-size: 25px;
          font-weight: 600;
        }
      }
      .work_item_desc {
        margin-top: 0;
        font-size: 20px;
      }
    }
    .work_item_tower {
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      align-items: center;
      width: 45%;
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .work_container {
      align-items: center;
      .work_item {
        padding-left: 0;
        .work_item_logo {
          width: 100%;
          max-height: 100%;
        }
        .work_item_info {
          h4 {
            font-size: 22px;

            width: 100%;
          }
        }
      }
    }
  }
}
