.vf_container {
  .vf_banner_container {
    display: flex;
    justify-content: space-around;
    height: 100vh;
    background-color: #1fbfe5;
    img {
      height: 100%;
    }
  }
  .vf_main_info_container {
    width: 80%;
    margin: 0 auto;
    .vf_title_parent {
      margin-bottom: 2rem;
      h1 {
        font-size: 100px;
        margin-bottom: 0;
      }
      p {
        margin: 0;
        font-size: 35px;
        font-weight: 400;
      }
    }
    .vf_brief {
      width: 100%;
      //   margin-right: 2%;
      margin-bottom: 4rem;
      div {
        margin-bottom: 0.5rem;

        h3 {
          margin: 0;
          font-size: 30px;
          font-weight: 800;
        }
        p {
          margin: 0;
          font-size: 20px;
          font-weight: 300;
        }
      }
    }
    .vf_process {
      .vf_process_firstRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        div {
          width: 49%;
          img {
            width: 100%;
            box-shadow: 5px 5px 10px lightgrey;
          }
          h3 {
            margin: 0;
            font-size: 25px;
            font-weight: 800;
          }
          p {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
      .vf_process_secRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
          width: 49%;
          margin: 1rem 0;
          img {
            width: 100%;
            box-shadow: 5px 5px 10px lightgrey;
          }
          p {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
    }
    @media only screen and (max-width: 800px) {
      .vf_title_parent {
        margin-bottom: 1rem;
        h1 {
          font-size: 70px;
          margin-top: 0.5rem;
        }
      }
      .vf_brief {
        margin-bottom: 2rem;
        div {
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
          }
        }
      }
      .vf_process {
        .vf_process_firstRow {
          flex-direction: column-reverse;
          div {
            width: 100%;
            h3 {
              font-size: 30px;
            }
            p {
              font-size: 25px;
            }
          }
        }
        .vf_process_secRow {
          flex-direction: column;
          div {
            width: 100%;
          }
        }
      }
    }
    .arrow_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .arrow {
        font-size: 25px;
        font-weight: 300;
        text-decoration: none;
        color: black;
        // float: right;
        margin: 2rem 0;
      }
      .arrow:hover {
        color: grey;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .vf_banner_container {
      align-items: center;
      img {
        width: 100vw;
        height: 50%;
      }
    }
  }
}
