.dm_container {
  .dm_banner_parent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #26a4ed;
    box-shadow: 5px 5px 10px lightgrey;

    height: 100vh;
    img {
      height: 80vh;
    }
  }
  .dm_info_container {
    margin: 2rem auto;
    width: 80%;
    h1 {
      font-size: 100px;
      margin-bottom: 0;
    }
    p {
      margin: 0;
      font-size: 35px;
      font-weight: 400;
    }
    .dm_main_info {
      margin-top: 2rem;
      .dm_design_text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 49%;
          margin-bottom: 1rem;
          img {
            width: 100%;
            box-shadow: 5px 5px 10px lightgrey;
          }
          h3 {
            margin: 0;
            font-size: 25px;
            font-weight: 800;
          }
          p {
            margin-top: 0;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
    }
    .dm_cans_imgs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div {
        width: 49%;
        img {
          width: 100%;
        }
      }
    }
    .arrow_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .arrow {
        font-size: 25px;
        font-weight: 300;
        text-decoration: none;
        color: black;
        // float: right;
        margin: 2rem 0;
      }
      .arrow:hover {
        color: grey;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .dm_banner_parent {
      img {
        height: auto;
        width: 100vw;
      }
    }
    .dm_title_parent {
      h1 {
        font-size: 70px;
        margin-top: 0.5rem;
      }
    }
    .dm_info_container {
      .dm_main_info {
        .dm_design_text {
          flex-direction: column;
          div {
            width: 100%;
            p {
              font-size: 25px;
            }
            h3 {
              font-size: 30px;
            }
          }
        }
      }
      .dm_cans_imgs {
        flex-direction: column;
        div {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
