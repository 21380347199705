.banner_container {
  width: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

#header_video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.onewheel_banner_title {
  z-index: 3;
  position: relative;
  width: 70%;
  top: 30%;
  margin: 0 auto;
}

.full_opaque_background {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: black;
  opacity: 0.4;
  overflow: hidden;
}

.onewheel_info {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  .Max_OW {
    height: auto;
    position: absolute;
    width: 10rem;
    right: 135px !important;
    top: 960px;
  }
  .stat_title {
    text-align: left;
    margin: 0% 10% -2%;
    font-size: 40px;
    font-weight: 400;
  }
  .onewheel_stats {
    width: 80%;
    min-height: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4rem auto;
    margin-bottom: 4rem;

    // padding-top: 4rem;
    .stat_box {
      width: 15rem;
      height: 17rem;
      background-color: white;
      // border: 10px solid #f9f9f9;
      border-radius: 50px;
      box-shadow: 5px 5px 10px lightgrey;
      padding: 1rem;

      .stat_number {
        font-weight: bold;
        font-size: 275px;
        color: #34ebab;
        margin: 0;
        text-align: center;
        top: 0;
        margin-top: -55px;
        text-shadow: 5px 7px #298f6b;
      }
      .stat_text {
        margin-top: -55px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        font-family: arial;
        text-transform: uppercase;
        font-weight: 200;
      }
    }
  }
  .ow_sponsors {
    text-align: left;
    width: 80%;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    font-weight: 400;
  }
  .ow_spon_img {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
    margin: 0 auto;
    width: 80%;
    margin-bottom: 6rem;
    div {
      width: 30%;
      a {
        img {
          width: 100%;
          max-height: 22rem;
        }
      }
      p {
        font-size: 20px;
      }
    }
  }
  .festivals {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    .festivals_title {
      margin-bottom: 2rem;
      font-size: 40px;
      font-weight: 400;
    }
    hr {
      height: 1px;
      border-width: 0;
      color: black;
      width: 100%;
      background-color: darkgray;
      margin: 4rem 0;
    }

    .sts {
      display: flex;
      flex-direction: row;
      .fest_img {
        width: 30%;
        background-color: lightblue;
        border-radius: 10px;
        box-shadow: 5px 5px 10px lightgrey;
        padding: 1rem;
        float: right;
        height: 100%;
      }
      .fth_img {
        background-color: black;
      }
      div {
        display: flex;
        flex-direction: column;
        width: 70%;
        // text-align: right;
        margin-right: 2rem;
        h1 {
          margin: 0;
          font-weight: 300;
          font-size: 30px;
        }
        h3 {
          margin: 0;
          font-size: 20px;
          font-weight: 200;
          font-size: 25px;
        }
        p {
          font-size: 20px;
          font-weight: 300;
        }
      }
      .links {
        bottom: 0;
        width: 50%;
        height: 100%;
        margin-top: 6%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .link {
          height: auto;
          width: 20%;
          bottom: 0;
          .link_img {
            height: auto;
            width: 100%;
            bottom: 0;
          }
        }
        .website {
          margin-left: 2rem;
        }
      }
    }

    .Trickit {
      display: flex;
      flex-direction: row;
      align-items: center;
      .fest_img {
        width: auto;
        height: 80%;
        // background-color: black;
        border-radius: 10px;
        box-shadow: 5px 5px 10px lightgrey;
        padding: 15px;
        min-height: 6rem;
        // float: right;
      }
      div {
        display: flex;
        flex-direction: column;
        // width: 50%;
        text-align: right;
        margin-left: 3rem;
        h1 {
          margin: 0;
          font-weight: 300;
          font-size: 30px;
        }
        h3 {
          margin: 0;
          font-size: 20px;
          font-weight: 200;
          font-size: 25px;
        }
        p {
          font-size: 20px;
          font-weight: 300;
        }
      }
      .links {
        bottom: 0;
        width: 50%;
        height: 100%;
        margin-top: 2%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-left: 50%;
        .link {
          height: auto;
          width: 20%;
          bottom: 0;
          .link_img {
            height: auto;
            width: 100%;
            bottom: 0;
          }
        }
        .website {
          margin-left: 2rem;
        }
      }
    }
  }
  .about_ow {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    // justify-content: space-evenly;
    .about_vid_container {
      margin-right: 4rem;
      min-width: 35%;
      .vert_vid_ow {
        height: auto;
        width: 100%;
        margin-right: 5rem;
        z-index: 1;
        position: relative;
      }
      .video_text {
        position: relative;
        z-index: 2;
        color: white;
        /* bottom: -435px; */
        /* left: -585px; */
        font-weight: bold;
        bottom: 50px;
        left: 10px;
      }
    }

    // .vert_vid_ow {
    //   color: white;
    //   z-index: 2;
    //   position: relative;
    //   left: 0;
    //   bottom: 0;
    // }
    .ow_about_title {
      margin-top: 0;
      font-size: 40px;
      font-weight: 400;
    }
    .ow_about_text {
      font-size: 20px;
      font-weight: 300;
    }
  }
}

@media only screen and (max-width: 800px) {
  .banner_container {
    height: 100vh;
  }
  .full_opaque_background {
    height: 100%;
  }

  .onewheel_info {
    .onewheel_stats {
      flex-wrap: wrap;
      margin: 1rem auto;
      .stat_box {
        margin: 1rem 0;
        width: 7rem;
        height: 7rem;

        .stat_number {
          font-size: 95px;
          padding: 40px 0rem;
          text-shadow: 3px 2px #298f6b;
        }
        .stat_text {
          font-size: 11px;
          margin-top: -63px;
        }
      }
    }
    .ow_sponsors {
      margin-top: 1rem;
    }
    .ow_spon_img {
      flex-direction: column;
      margin-bottom: 2rem;
      div {
        width: auto;
        p {
          width: 90%;
          margin: 0 auto;
        }
        a {
          display: flex;
          justify-content: space-around;
          img {
            width: 90%;
          }
        }
      }
    }
    .about_ow {
      flex-direction: column;
      width: 100%;
      .about_vid_container {
        margin-right: 0;
        width: 100%;

        .vert_vid_ow {
          margin-right: 0;
        }
      }
      div {
        width: 70%;
        margin: 0 auto;
      }
    }
    .festivals {
      .sts {
        flex-direction: column-reverse;
        div {
          // margin-right: 0;
          width: 85%;
          margin: 2rem auto;
          margin-bottom: 0;
        }
        .fest_img {
          width: 80%;
          margin: 0 auto;
        }
        .links {
          margin-left: 0;
          width: auto;
          height: auto;
        }
      }
      .Trickit {
        flex-direction: column;
        div {
          width: 85%;
          margin: 2rem auto;
          text-align: left;
          margin-bottom: 0;
        }
        .fest_img {
          min-height: auto;
          width: 70%;
        }
        .links {
          margin-left: 0;
          justify-content: flex-start;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
