.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.smooth-scroll {
  display: block;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}
