.pp_parent_cont {
  margin: 0 auto;
  padding-bottom: 2rem;
  h2 {
    text-align: left;
    font-size: 40px;
    font-weight: 400;
  }
  .pp_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .pp_item {
      // background-color: black;
      min-height: 20rem;
      width: 25%;
      padding: 0 2rem;
      align-items: center;
      // text-align: center;
      display: flex;
      justify-content: space-evenly;
      border-radius: 2rem;
      margin: 1rem;
      transform: scale(1);
      transition: transform 0.3s ease;
      // box-shadow: 5px 5px 5px darkgrey;

      img {
        width: 100%;
        opacity: 1;
        z-index: 1;
        position: inherit;
        transition: opacity 0.3s ease;
        box-shadow: 5px 5px 5px darkgrey;

        border-radius: 10px;
      }
      .hidden_text {
        opacity: 0;
        z-index: 2;
        position: absolute;
        transition: opacity 0.3s ease;
        color: black;
        text-align: left;
        padding: 0 4rem;
        h3 {
          font-size: 30px;
          // font-family: Arial, Helvetica, sans-serif;
          font-weight: 600;
        }
        p {
          font-size: 20px;
          a {
            color: black;
          }
        }
      }
    }
    .pp_item:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease;
      img {
        opacity: 0.2;
        transition: opacity 0.3s ease;
      }
      .hidden_text {
        opacity: 1;
        color: black;
        transition: opacity 0.3s ease;
        text-align: left;
        padding: 0 4rem;
        width: 60%;
        h3 {
          font-size: 30px;
          // font-family: Arial, Helvetica, sans-serif;
          font-weight: 600;
        }
        p {
          font-size: 20px;
          a {
            color: black;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    .pp_container {
      display: none;
      .pp_item {
        width: auto;
      }
      .pp_item:hover {
        .hidden_text {
          padding: 0 2rem;
        }
      }
    }
    .mobile {
      display: block;
      width: 100%;
    }
  }
}
